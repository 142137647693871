import logo from './logo.svg';
import './index.css';
import './App.css';
import { BrowserRouter, Route, Link, Switch, Redirect } from "react-router-dom";
import React, { useState } from "react";
import Comingsoon from './Page/Comingsoon';
import Home from './Page/Home';
import Battlefield from './Page/Battlefield';
import Voucher from './Page/Voucher';
import Learn from './Page/Learn/Learn';
import Package from './Page/Package/Package';

function App() {

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/voucher" exact>
          <Voucher />
        </Route>
        <Route path="/learn">
          <Learn></Learn>
        </Route>
        <Route path="/voucher/:order_id" component={Voucher} exact>
        </Route>
        {/* <ProtectedRoute
          path="/dashboard"
          component={Dashboard}
        /> */}
        <Route path="/" exact>
          <Redirect to="/paket" />
        </Route>
        <Route path="/battlefield" component={Battlefield}></Route>
        <Route path="/paket" component={Package}></Route>
        <Route path="/:ref" component={Home} exact>
          {/* <Home /> */}
        </Route>
        {/* <Route path="*" exact>
          <Comingsoon />
        </Route> */}
      </Switch>
    </BrowserRouter>
  );
}

export default App;
