import React, { Component } from 'react'
import PropTypes from 'prop-types'
import btfImage from "./assets/btf.png";

const Section2 = () => {
    return (
        <div class="container">
            <div class="grid grid-cols-12 justify-center">
                <div class="col-span-12 lg:col-span-6 ml-5">
                    <img src={btfImage} class="w-10/12 shadow-xl "></img>
                </div>
                <div class="col-span-12 lg:col-span-6 mr-10 mt-10 ml-5 md:ml-0 lg:ml-0 md:mt-0 place-self-center">
                    <h1 class="font-sans font-semibold  text-4xl mb-5 md:-mt-5">Bingung, mau latihan soal dimana?</h1>
                    <p class="text-xl">Battlefiled bisa menjadi solusinya. Battlefield merupakan gudangnya latihan soal, tersedia sepuluh ribu soal latihan UTBK.</p>
                    <br></br>
                    <p class="text-xl">Menurut pejuang UTBK, Battlefiled telah terbukti menjadi tempat yang menyenangkan dan bikin nagih untuk latihan soal, bahkan ada soal yang mirip. Yakin gak penasaran? </p>
                </div>
            </div>
        </div>
    )
}

export default Section2;