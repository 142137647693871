import React, { useState,useEffect} from 'react'
import kodenotelp from "./../Battlefield/Section/data/kodenotelp.json"
const Learn = () => {

    const [dataTelp, setDataTelp] = useState(kodenotelp)
    const [togle,setToggle] = useState(false);

    const keyUp=(e)=>{
       const resultData= kodenotelp.filter(result=>result[0].includes(e.target.value));
       setDataTelp(resultData)
    }
    useEffect(() => {
        console.log("HI Toggle is "+togle)
    }, [togle])


    const toggleHandle=()=>{
        setToggle(togle=>!togle)
    }
    return (
        <div class="grid grid-cols-12 h-screen p-5 bg-white shadow-lg rounded-3xl overflow-y-scroll">
            <div class="col-span-12 mx-auto">
                <button class="btn bg-red-600" onClick={toggleHandle}>
                    Click Me Im {(togle?"ON":"OFF")}
                </button>

            </div>
            <div class="col-span-12 mx-auto">
                <p>Search Kode Nomor</p>
                <div><input type="text" placeholder="Masukan Nama Negara" onKeyUp={keyUp.bind(this)} 
                class="bg-gray-500 rounded-xl text-white p-2"></input></div>
                <ul>
                    {dataTelp.map(result => {
                        return <li>
                            <button>{result[0]+"______"+result[1]}</button>
                        </li>
                    })}
                </ul>
            </div>
        </div>
    )
}

export default Learn;