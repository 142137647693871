import axios from "axios";
import Strings from "../../Services/Strings"

function checkout(name, email, phone, payment_gateway_type,type) {
    var form = new FormData();
    form.append("name", name);
    form.append("email", email);
    form.append("phone", phone);
    form.append("payment_gateway_type", payment_gateway_type);
    form.append('package_type',type)
    return axios({
        method: "POST",
        url: Strings.base_url+"package/action",
        data: form,
    })
}

function package_list(){
    return axios({
        method:"GET",
        url:Strings.base_url+"package/get_all",
    })
}


const Services = { checkout,package_list };
export default Services;