import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import dataTesti from "./data/datatesti.json";
const ItemTestimoni = (props) => {

    const [readMore, setReadMore] = useState(false);
    const totalStringShow = 160
    return (
        <div class="p-3">
            <div className="bg-white shadow-lg rounded-3xl py-12 px-4 sm:px-6 md:flex md:flex-col md:py-5 md:pl-0 md:pr-10 lg:pr-16">
                <blockquote className=" md:flex-grow md:flex md:flex-col">
                    <div className="relative text-lg font-medium text-blue-600 md:flex-grow">
                        <svg
                            className="absolute -top-3 left-0 transform -translate-x-3 -translate-y-2 h-6 w-6 text-blue-600"
                            fill="currentColor"
                            viewBox="0 0 32 32"
                            aria-hidden="true"
                        >
                            <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                        </svg>
                        <p className="relative text-blue-800 text-sm md:text-lg">
                            {props.content.data.substring(0, totalStringShow)}
                            {((!readMore && props.content.data.length > totalStringShow) ? <span class="text-blue-600 hover:text-blue-400" style={{ cursor: "pointer" }} onClick={() => { setReadMore(true) }} >..Read More</span> : "")}
                            {(readMore ? props.content.data.substring(totalStringShow) : "")}
                        </p>
                    </div>

                </blockquote>

            </div>
            <footer className="mt-4">
                <div className="flex items-start justify-center">
                    <div className="">
                        <div className="text-base font-medium text-white">{props.content.name}</div>
                        <div className="text-base font-medium text-indigo-200">{props.content.username}</div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

const Section4 = () => {
    const settings = {
        dots: true,
        speed: 500,
        infinite: true,
        centerMode: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <React.Fragment>
            <div class="relative bg-gradient-to-t from-blue-900 to-blue-500">
                <h1 class="text-center text-3xl md:text-5xl font-extrabold pt-10 text-white">TESTIMONI</h1>
                <h2 class="text-center text-md md:text-md font-extrabold mb-5  text-white">Battlefield #KataTemanLulusNegeri</h2>

                <div class="relative">
                    <div>
                        <Slider {...settings}>
                            {dataTesti.map((result, key) => {
                                console.log(key)
                                return (
                                    <ItemTestimoni content={result} />
                                )
                            })}
                        </Slider>
                    </div>
                    <div class="relative z-50 text-center top-8">
                        <button onClick={()=>{window.location.replace("https://bit.ly/3ieSyR4")}} class="btn btn-sm relative left-0 right-0 text-white">
                            Lihat Lebih Banyak
                        </button>
                        
                    </div>
                </div>
                <div class="relative">
                    <br></br>
                    <br></br>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Section4;