import React, { Component } from 'react'
import PropTypes from 'prop-types'
import kodenotelp from "./data/kodenotelp.json";
import Services from '../Services';
class SelectCountryCode extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        e.preventDefault();
        this.props.onCountryNumberChange(e.target.value);
    }

    render() {
        return (
            <select class="rounded-2xl" style={{ width: "30%", marginRight: "5px" }} onChange={this.handleChange} required>
                {
                    kodenotelp.map((result, index) => {
                        return <option value={result[2]}>{result[1]} - {result[0]}</option>
                    })
                }
            </select>
        );
    }
}



class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displaySummary: false,
            name: "",
            email: "",
            phone: "",
            paymentType: "MT",
            countryNumber: 62,
            btn_checkout_text_disabled: false,
            btn_checkout_text: "Bayar Sekarang",
            type: null,
            package_list: [],
            package_name:"",
            package_price:0,
            package_final_price:0,
            package_notes:"",
            package_loaded: false
        }
        this.submitHandle = this.submitHandle.bind(this)
        this.handleBack = this.handleBack.bind(this);
        this.handleCheckout = this.handleCheckout.bind(this);
        this.handlePaymentGateway = this.handlePaymentGateway.bind(this);
        this.handlePackageChange = this.handlePackageChange.bind(this);
    }

    

    componentDidMount() {
        const type = new URLSearchParams(window.location.search).get('type')
        let self = this;
        Services.package_list().then((res) => {
            if (res.data.total_result != 0) {
                self.setState({ package_loaded: true, package_list: res.data.result })
            } else {
                self.setState({ package_loaded: false })
            }

        }).catch(err => {

        })
        this.setState({ type });
    }
    handlePaymentGateway(value, e) {
        this.setState({ paymentType: value })
    }
    handleCountryNumberChange = countryNumber => {
        this.setState({ countryNumber })
    }

    submitHandle(e) {
        e.preventDefault();
        let name = e.target.fullname.value;
        let email = e.target.email.value;
        let phone = e.target.phone.value;
        console.log(name, email, phone)
        this.setState({
            name, email, phone
        })
        this.setState({ displaySummary: true })
        window.fbq('track', 'Add to cart');
    }

    handleBack() {
        this.setState({ displaySummary: false })
    }

    handleCheckout() {
        let name = this.state.name;
        let email = this.state.email;
        let phone = this.state.phone;
        let paymentType = this.state.paymentType;
        let type = this.state.type;
        this.setState({ btn_checkout_text_disabled: true, btn_checkout_text: "Memproses.." })
        Services.checkout(name, email, phone, paymentType, type).then((res) => {
            console.log(res.data)
            let data = res.data.result;
            let order_id = data.order_id;
            if (order_id.includes("MT")) {
                console.log("MIDTRANS")
                console.log(data.midtrans_token)
                window.snap.pay(data.midtrans_token, {
                    // skipOrderSummary: true,
                    onSuccess: () => {
                        window.location.replace("/voucher/" + order_id);
                    },
                    onPending: () => {
                        window.location.replace("/voucher/" + order_id);
                    },
                    onError: () => {

                    },
                    onClose: () => {
                        // Redirect dengan payment gateway id
                        window.location.replace("/voucher/" + order_id);
                    }
                })
            } else {
                window.location.replace(data.xendit_url);
                console.log("XE")
            }
        }).catch((err) => {
            this.setState({ btn_checkout_text_disabled: false, btn_checkout_text: "Bayar Sekarang" })
        })
    }
    handlePackageChange(e) {
        if (e.target.value != "") {
            let value=e.target.value;
            let explodeData=value.split("#");
            this.setState({ type: explodeData[0],package_name:explodeData[1],package_final_price:explodeData[2],package_notes:explodeData[3],package_price:explodeData[4] })
        } else {
            this.setState({ type: null })
        }
    }
    render() {
        return (
            <div class="container" style={{ height: "50%" }}>
                <br></br>
                <br></br>
                <h1 class="text-center text-3xl md:text-5xl font-extrabold mb-5" style={{ color: "#0d4273" }}>GET THE VOUCHER HERE</h1>
                <div class="grid grid-cols-12 p-2" style={{ display: (this.state.displaySummary ? "none" : "") }}>
                    <div class="col-span-12 lg:col-span-7 bg-gradient-to-r from-gray-100 to-gray-200 rounded-lg shadow-lg p-16">
                        <form class="w-full max-w-lg" onSubmit={this.submitHandle}>
                            <div class="md:flex md:items-center mb-6">
                                <div class="md:w-1/3">
                                    <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                                        Nama
                                    </label>
                                </div>
                                <div class="md:w-2/3">
                                    <input name="fullname" class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500 shadow-md" id="inline-full-name" type="text" required />
                                </div>
                            </div>
                            <div class="md:flex md:items-center mb-6">
                                <div class="md:w-1/3">
                                    <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-password">
                                        Email
                                    </label>
                                </div>
                                <div class="md:w-2/3">
                                    <input name="email" class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500 shadow-md" id="inline-full-name" type="text" required />
                                    <p class="text-white" style={{ display: (this.state.emailInputInfo ? "" : "none") }}>*Pastikan alamat e-mail yang diisikan benar, karena kode pembayaran akan dikirimkan ke alamat e-mail tersebut.</p>
                                </div>
                            </div>
                            <div class="md:flex md:items-center mb-6">
                                <div class="md:w-1/3">
                                    <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-password">
                                        Nomor Hp
                                    </label>
                                </div>
                                <div class="md:w-2/3">
                                    <input name="phone" class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500 shadow-md" id="inline-full-name" type="text" required />
                                </div>
                            </div>
                            <div class="md:flex md:items-center mb-6">
                                <div class="md:w-1/3">
                                    <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-password">
                                        Paket
                                    </label>
                                </div>
                                <div class="md:w-2/3">
                                    <select id="country" onChange={this.handlePackageChange} name="country" autocomplete="country" class="focus:ring-indigo-500 focus:border-indigo-500 py-2  w-full shadow-sm sm:text-sm border-gray-300 rounded-md" required>
                                        <option value="" selected={(this.state.type == null ? "selected" : "")}>Pilih Paket</option>
                                        {(this.state.package_loaded ?

                                            this.state.package_list.map((result, index) => {
                                                return <option value={result.id+"#"+result.name+"#"+result.final_price+"#"+result.notes+"#"+result.price} selected={(this.state.type==result.id?"selected":"")}>{result.name}</option>
                                            })
                                            : <option value="" selected={(this.state.type == null ? "selected" : "")}>Loading..</option>)}
                                       
                                    </select>
                                </div>
                            </div>
                            <div class="md:flex md:items-center">
                                <div class="md:w-1/3"></div>
                                <div class="md:w-2/3">
                                    <button type="submit" class="shadow w-full lg:w-36 bg-blue-900 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded">
                                        Beli
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-span-12 lg:col-span-5 bg-gradient-to-r from-blue-900 to-blue-500 rounded-lg shadow-lg pt-10 pb-10 pr-3 pl-8 lg:p-16">
                        <div class="text-2xl lg:text-4xl font-extrabold  -space-y-2">
                            <p class="text-white">VOUCHER</p>
                            <p class="text-white">LULUSNEGERI</p>
                            {(this.state.package_name != '' ? "": <p class="text-white">PREMIUM</p>)}
                            {(this.state.package_name != '' ? <span class="bg-yellow-400 text-black pl-3 pr-3 rounded-3xl">{this.state.package_name}</span> : "")}
                        </div>
                        {(this.state.package_notes != '' ? <p class="text-white font-semibold mt-1">{this.state.package_notes}</p> : "")}
                        <p class="text-white">Teman Berlatih untuk Persiapan Seleksi Perguruan Tinggi Negeri</p>                    </div>
                </div>
                {/* <div class="mbr-row mbr-jc-c mbr-pt-4" style={{ display: (this.state.displaySummary ? "none" : "") }}>
                    <div class="mbr-col-lg-7 mbr-col-md-10 mbr-col-sm-12" data-form-type="formoid">
                        <form class="mbr-form" onSubmit={this.submitHandle} target="_top" >
                            <div class="mbr-form-row">
                                <div class="field mbr-col-sm-12 mbr-col" style={{ width: "100%" }} >
                                    <input
                                        class="field-input display-4 rounded-2xl" type="text" name="fullname"
                                        placeholder="Nama Lengkap" required
                                    />
                                </div>
                                <div class="field mbr-col-sm-12 mbr-col" style={{ display: "flex" }} data-for="form[data][2][1]">
                                    <SelectCountryCode onCountryNumberChange={this.handleCountryNumberChange} />
                                    <input class="field-input display-4 rounded-2xl" style={{ width: "75%" }} type="number" placeholder="Nomor WhatsApp"
                                        name="phone"
                                        required />
                                </div>
                                <div class="field mbr-col-sm-12 mbr-col" style={{ width: "50%" }} data-for="form[data][0][1]">
                                    <input class="field-input display-4 rounded-2xl" type="email" onKeyUp={this.keyUpHandler} placeholder="Alamat Email" name="email" required />
                                    <p class="text-white" style={{ display: (this.state.emailInputInfo ? "" : "none") }}>*Pastikan alamat e-mail yang diisikan benar, karena kode pembayaran akan dikirimkan ke alamat e-mail tersebut.</p>
                                </div>

                                <div class="mbr-section-btn mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-12 align-center mt-2"><button
                                    type="submit" class="btn w-100 btn-success btn-form display-4 bg-green-500 border-none rounded-5xl">Pre order Voucher</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div> */}
                <div class="mbr-row mbr-jc-c mbr-pt-4" style={{ display: (this.state.displaySummary ? "" : "none") }}>
                    <div class="mbr-col-lg-7 mbr-col-md-10 mbr-col-sm-12" data-form-type="formoid">

                        <article class="card">
                            <div class="container">
                                <div class="card-body">
                                    <div class="payment-info -ml-10 md:ml-0">
                                        <h4 class="mb-4 mt-2 font-weight-bold text-center">Ringkasan Order</h4>

                                        <table class="table table-responsive">
                                            <tr>
                                                <td>Nama</td>
                                                <td>{this.state.name}</td>
                                            </tr>
                                            <tr>
                                                <td>Email</td>
                                                <td>{this.state.email}</td>
                                            </tr>
                                            <tr>
                                                <td>No Whatsapp</td>
                                                <td>{this.state.phone}</td>
                                            </tr>
                                            <tr>
                                                <td>Produk</td>
                                                <td>Voucher {this.state.package_name}</td>
                                            </tr>
                                            <tr>
                                                <td>Deskripsi</td>
                                                <td>{this.state.package_notes}</td>
                                            </tr>
                                            <tr>
                                                <td>Petunjuk</td>
                                                <td>Saat pembayaran sukses maka kode pembayaran akan dikirimkan ke e-mail.<br />Voucher dapat digunakan di website lulusnegeri.com melalui halaman lulusnegeri.com/redeem.</td>
                                            </tr>

                                        </table>


                                    </div>
                                </div>
                            </div>
                        </article>
                        <article class="card mt-3">
                            <div class="container">
                                <div class="card-body">

                                    <div class="payment-type">
                                        <h4 class="font-weight-bold text-center">Pilih Metode Pembayaran</h4>
                                        <div class="types flex justify-space-between">
                                            <div class={(this.state.paymentType == "MT" ? "type selected" : "type")} onClick={this.handlePaymentGateway.bind(this, "MT")}>
                                                <div class="logo">
                                                    <img src="https://lulusnegeri.com/xfile/midtrans.jpeg" style={{ maxWidth: "100%" }}></img>
                                                </div>
                                                <div class="text">
                                                    <p>BAYAR DENGAN GOPAY DAN BANK TRANSFER</p>
                                                </div>
                                            </div>
                                            <div class={(this.state.paymentType == "XE" ? "type selected" : "type")} onClick={this.handlePaymentGateway.bind(this, "XE")}>
                                                <div class="logo">
                                                    <img src="https://lulusnegeri.com/xfile/xendit.jpeg" style={{ maxWidth: "100%" }}></img>
                                                </div>
                                                <div class="text">
                                                    <p>BAYAR DENGAN OVO, DANA, LINKAJA, ALFA MART, LAINYA</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="payment-info">
                                        <h4 class="mb-4 mt-2 font-weight-bold">Rincian Harga</h4>
                                        <table class="table">
                                            {/* <tr>
                                                <td>Harga</td>
                                                <td style={{ textAlign: "right" }}>Rp10.000</td>
                                            </tr> */}
                                            <tr>
                                                <td><b>Total Pembayaran</b></td>
                                                <td style={{ textAlign: "right" }}>{(this.state.package_final_price==this.state.package_price?"":<el class="line-through text-xs">Rp{this.state.package_price}</el>)} Rp{this.state.package_final_price}</td>
                                            </tr>
                                        </table>


                                    </div>
                                </div>
                            </div>
                        </article>



                        <div class="mbr-form-row mt-3">
                            <div class="mbr-section-btn mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-12 align-center">
                                <button class="btn btn-primary btn-form display-4 w-100 border-none bg-blue-900 rounded-2xl" onClick={this.handleCheckout} disabled={(this.state.btn_checkout_text_disabled ? "disabled" : "")}>{this.state.btn_checkout_text}</button>
                                <button class="btn btn-link btn-form display-4 w-100 rounded-2xl text-blue-800" onClick={this.handleBack}>Kembali</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
    }
}

export default Form;