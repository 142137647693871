import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom"

class Comingsoon extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <section class="sign-in-page">
            <div class="container">
                <div class="row justify-content-center align-items-center height-self-center">
                    <div class="col-lg-5 col-md-12 align-self-center">
                        <div class="sign-user_card ">
                            <div class="sign-in-page-data">
                                <div class="sign-in-from w-100 m-auto">
                                    <h3 class="mb-3 text-center">Coming Soon</h3>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="d-flex justify-content-center links">
                                    Stay tune for something awesome
                                </div>
                                <div class="d-flex justify-content-center links">
                                    {/* <a href="reset-password.html" class="f-link">Lupa Password?</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            );
    }
}

 
export default Comingsoon;