import React, { Component } from 'react'
import PropTypes from 'prop-types'
import macbookProMockup from "./assets/macbook-pro.png"
class Section1 extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div class="container">
                <a href="#form">
                    <button class="flex btn border-none btn-primary mx-auto mb-10 -mt-5 w-80 rounded-2xl text-dark text-xl" style={{ background: "#50b6fe" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M2 6a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 100 4v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2a2 2 0 100-4V6z" />
                        </svg>
                        Dapatkan Voucher
                    </button>
                </a>
                <div class="grid grid-cols-12 justify-center">
                    <div class="col-span-12 lg:col-span-6 ml-5 mr-10">
                        <h1 class="font-sans font-semibold text-3xl md:text-4xl mb-5" style={{color:"#0d4273"}}>Insecure menghadapi UTBK?</h1>
                        <p class="text-xl">Ya, kamu tidak sendiri, itu hal wajar yang dialami para pejuang UTBK. Insecure bisa muncul karena kurangnya persiapan, seperti kurang memahami materi, tidak terbiasa mengerjakan soal, dll.</p>
                        <br></br>
                        <p class="text-xl">Oleh karena itu, sangat penting untuk kamu melakukan persiapan salah satunya dengan latihan soal. </p>
                    </div>
                    <div class="col-span-12 lg:col-span-6 mt-10 lg:mt-0">
                        <img src={macbookProMockup} class="mx-auto w-10/12 "></img>
                    </div>
                </div>
            </div>
        );
    }
}

export default Section1;