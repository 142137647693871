import React, { Component } from 'react'
import PropTypes from 'prop-types'
import banner from "./assets/banner-btf-presale.jpg"

class Banner extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (

            <div class="relative">
                <img src={banner} class=" w-full"></img>
            </div>
        );
    }
}

export default Banner;