import React, { Component } from 'react'
import PropTypes from 'prop-types'
class Home extends Component {
     constructor(props) {
         super(props);
         this.state = {  }
     }
     render() { 
         return ( <p>Home</p> );
     }
 }
  
 export default Home;