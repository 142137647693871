import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Layout from '../../Component/Layout';
import Form from "../Package/Section/Form";
import "./Package.css";
import Banner from './Section/Banner';
import Section1 from './Section/Section1';
import Section2 from './Section/Section2';
import Section3 from './Section/Section3';
import Section4 from './Section/Section4';
import Section3a from './Section/Section3a';
class Package extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount(){
        window.fbq('track', 'PageView');
    }
    render() {
        return (
            <Layout>
                
                <section class="contacts2 cid-sqz7gbxx6X bg-gradient-to-r from-gray-100 bg-white" id={"form"}>
                <Form />
                </section>

            </Layout>
        );
    }
}

export default Package;