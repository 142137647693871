import React, { Component } from 'react'
import { Link, Redirect } from "react-router-dom";

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogged: true,
        }
        this.handleLogout = this.handleLogout.bind(this);
    }
    handleLogout(event) {
        event.preventDefault();
        localStorage.clear();
        this.setState({ isLogged: false })
    }
    render() {
        return (
            <div>
                <amp-sidebar id="sidebar" class="cid-sqz8fZY59D" layout="nodisplay" side="right">
                    <div class="builder-sidebar" id="builder-sidebar">
                        <button on="tap:sidebar.close" class="close-sidebar">
                            <span></span>
                            <span></span>
                        </button>


                        <ul class="navbar-nav nav-dropdown" data-app-modern-menu="true">
                            <li class="nav-item">
                                <a class="nav-link link text-black display-4"
                                    href="https://lulusnegeri.com">Home</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link link text-black display-4"
                                    onClick={() => window.location.replace("/#preorder")}>Paket</a>
                            </li>
                            <li class="nav-item"><a class="nav-link link text-black display-4" href="/voucher">
                                Print Voucher</a></li>
                        </ul>


                    </div>
                </amp-sidebar>

                <section class="menu horizontal-menu cid-sqz8fZY59D" id="menu2-4">




                    <nav class="navbar navbar-dropdown navbar-expand-lg" >
                        <div class="menu-container container-fluid">
                        <span class="navbar-logo">
              <amp-img src="https://lulusnegeri.com/xfile/background/logo.png?v1" layout="responsive" width="250.10126582278482" height="120" alt="Mobirise" class="mobirise-loader">
                  <div placeholder="" class="placeholder">
                                <div class="mobirise-spinner">
                                    <em></em>
                                    <em></em>
                                    <em></em>
                                </div></div>
                  <a href="./"></a>
              </amp-img>
          </span>
                            <div class="collapse navbar-collapse" id="navbarSupportedContent" style={{justifyContent:"left"}}>

                                <ul class="navbar-nav nav-dropdown" data-app-modern-menu="true">
                                    <li class="nav-item"><a class="nav-link link text-black display-4" href="https://lulusnegeri.com">
                                        Home</a></li>
                                    <li class="nav-item active">
                                        <a class="nav-link link text-black display-4"
                                            onClick={() => window.location.replace("/paket")}>Paket</a>
                                    </li>
                                    <li class="nav-item"><a class="nav-link link text-black display-4" href="/voucher">
                                        Print Voucher</a></li>
                                </ul>


                            </div>

                            <button on="tap:sidebar.toggle" class="ampstart-btn hamburger">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </div>
                    </nav>
                </section>
            </div>
        );
    }
}

export default Navbar;