import axios from "axios";
import Strings from "./../../Services/Strings"

function checkout(name, email, phone, payment_gateway_type) {
    var form = new FormData();
    form.append("name", name);
    form.append("email", email);
    form.append("phone", phone);
    form.append("payment_gateway_type", payment_gateway_type);
    return axios({
        method: "POST",
        url: Strings.base_url+"battlefield/action",
        data: form,
    })
}


const Services = { checkout };
export default Services;