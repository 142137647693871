import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import dataTesti from "./data/databtf.json";
const ItemTestimoni = (props) => {

    const [readMore, setReadMore] = useState(false);
    const totalStringShow = 160
    return (
        <div class="p-3">
            <div className="bg-white shadow-lg rounded-3xl py-12 px-4 sm:px-6 md:flex md:flex-col md:py-5 mb-10 md:pl-0 md:pr-10 lg:pr-16">
                <div className=" md:flex-grow md:flex md:h-36 md:flex-col">
                    <div className="relative text-lg font-medium text-blue-600 md:flex-grow">
                        <h2 class="text-center bg-blue-700 mb-2 text-white w-8 h-8 flex items-center justify-center mx-auto rounded-full">{(props.keyNumb+1)}</h2>
                        <p className="relative text-center text-gray-600 text-base md:text-lg">
                            {props.content.data}
                        </p>
                    </div>

                </div>
                

            </div>
        
        </div>
    )
}

const Section3a = () => {
    const settings = {
        dots: true,
        speed: 500,
        infinite: true,
        centerMode: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <React.Fragment>
            <div class="relative bg-gradient-to-t from-gray-200 to-white">
                <h1 class="text-center text-3xl md:text-5xl font-extrabold pt-10 text-gray-700">BATTLEFIELD</h1>
                <h2 class="text-center text-md md:text-md font-extrabold mb-5  text-gray-700">Fitur Battlefield</h2>

                <div class="relative">
                    <div>
                        <Slider {...settings}>
                            {dataTesti.map((result, key) => {
                                console.log(key)
                                return (
                                    <ItemTestimoni content={result} keyNumb={key} />
                                )
                            })}
                        </Slider>
                    </div>

                </div>
                <div class="relative">
                    <br></br>
                    <br></br>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Section3a;