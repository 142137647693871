import React, { useState, useEffect } from "react";
import Layout from "../../Component/Layout";
import { Link } from 'react-router-dom'
import Services from "./Services";
import "./Voucher.css";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import lulusnegerilogo from './logo.png';


function CardTicket(props) {
    const [copied, setCopied] = useState(false);
    function handlePaymentContinue(order_id) {
        if (order_id.includes("MT")) {
            let snap_token = props.data.result.midtrans_token;
            let order_id = props.data.result.order_id;
            window.snap.pay(snap_token, {
                // skipOrderSummary: true,
                onSuccess: () => {
                    window.location.replace("/voucher/" + order_id);
                },
                onPending: () => {
                    window.location.replace("/voucher/" + order_id);
                },
                onError: () => {

                },
                onClose: () => {
                    // Redirect dengan payment gateway id
                    window.location.replace("/voucher/" + order_id);
                }
            })
        } else {
            let uri = props.data.result.xendit_url;
            window.location.replace(uri);
        }
    }
    if (props.is_found) {
        // console.log(props.is_found)
        // console.log(props.data.result.user_name)
        console.log(props.data.result);
        if (props.data.status_code == 200) {
            let transaction_detail=JSON.parse(props.data.result.transaction_detail)
            console.log(transaction_detail.keterangan)
            // Sukses
            return (
                <div>
                    <div class="contenido">

                        <div class="details">
                            <div>
                                <img src={lulusnegerilogo}></img>
                            </div>
                            <div class="tinfo">
                                Nama
                            </div>
                            <div class="tdata name">
                                {props.data.result.user_name}
                            </div>
                            <div class="tinfo">
                                Voucher
                            </div>
                            <div class="tdata">
                                {props.data.result.voucher_name}
                            </div>
                            <div class="tinfo">
                                Kode-pembayaran
                            </div>
                            <div class="tdata">
                                {props.data.result.order_id}
                            </div>
                            <div class="tinfo">
                                Keterangan
                            </div>
                            <div class="tdata">
                                {(transaction_detail.keterangan !== undefined?transaction_detail.keterangan:'Pembelian Berhasil')}
                            </div>

                            {/* <div class="link">
                                <a href="#">SEE MORE</a>
                            </div> */}
                        </div>
                        <div class="ticket">
                            <div class="hqr">
                                <div class="column left-one"></div>
                                <div class="column center text-center">
                                    <CopyToClipboard text={props.data.result.voucher_code}
                                        onCopy={() => setCopied(true)}>
                                        <button style={{ maxWidth: "100%", backgroundColor: "#af1b36", borderColor: "#af1b36", color: "#FFFFFF", paddingRight: "25px", paddingLeft: "25px" }} href="#" class="btn btn-alert btn-sm"><i class="fas fa-copy mr-2"></i>

                                            <div class="text-center">
                                                <p style={{ fontSize: "10px", marginBottom: "-3px" }}>Kode Voucher</p>
                                                {props.data.result.voucher_code}</div>
                                        </button>
                                    </CopyToClipboard>
                                    <p>{copied ? <span style={{ color: 'red' }}>kode voucher berhasil di salin.</span> : null}</p>
                                </div>
                                <div class="column right-one"></div>
                            </div>
                        </div>

                    </div>
                    <p class="text-white" style={{ fontSize: "12px" }}>Silakan untuk menyimpan kode pembayaran dan kode voucher untuk digunakan di sistem Lulusnegeri. Pastikan kode voucher ini rahasia karena hanya dapat ditukarkan satu kali.</p>
                </div>
            )
        } else if (props.data.status_code == 201) {
            // Pending
            return (
                <div class="card text-center" style={{ background: "#FFFFFF" }}>
                    <div class="card-body">
                        <div class="card-header">
                            Kode pembayaran : {props.data.result.order_id}
                        </div>
                        <h5 class="card-title">Pemesanan Voucher Pending</h5>
                        <p class="card-text">Silakan untuk melanjutkan pembayaran melalui tombol dibawah ini.</p>
                        <button class="btn btn-primary" onClick={handlePaymentContinue.bind(this, props.data.result.order_id)}>Lanjutkan Pembayaran</button>
                        <p class="text-dark" style={{ fontSize: "10px" }}>*silakan refresh secara berkala jika kamu sudah melakukan pembayaran.</p>

                    </div>
                </div>
            )
        } else if (props.data.status_code == 301) {
            // Expired
            return (
                <div class="card text-center" style={{ background: "#FFFFFF" }}>
                    <div class="card-body">
                        <div class="card-header">
                            Kode pembayaran : {props.data.result.order_id}
                        </div>
                        <h5 class="card-title">Pemesanan Voucher Kadaluarsa</h5>
                        <p class="card-text">Silakan untuk melakukan pembelian ulang</p>
                        {/* <Link class="btn btn-primary" to="/battlefield">Beli Voucher</Link> */}
                    </div>
                </div>
            )

        } else {
            return (
                <div class="card text-center" style={{ background: "#FFFFFF" }}>
                    <div class="card-body">
                        <p class="card-text">Silakan untuk melakukan pembelian ulang</p>
                        {/* <Link class="btn btn-primary" to="/battlefield">Beli Voucher</Link> */}
                    </div>
                </div>
            )
        }
    } else {
        return (
            <div class="card text-center" style={{ background: "#FFFFFF" }}>
                <div class="card-body">
                    <h5 class="card-title">Kode Pembayaran Tidak Ditemukan</h5>
                    <p class="card-text">Silakan untuk menghubungi tim admin lulusnegeri</p>
                </div>
            </div>
        )
    }

}
const Voucher = (props) => {
    const [is_found, setIsFound] = useState(false)
    const [is_params, setParams] = useState(false)
    const [is_load, setLoaded] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        try {
            let order_id = props.match.params.order_id;
            setParams(true);
            Services.getVoucher(order_id).then(res => {
                setLoaded(true);
                setIsFound(true);
                setData(res.data)
                if (res.data.status_code == 201) {
                    window.fbq('track', 'Initial checkout');
                } else if (res.data.status_code == 200) {
                    window.fbq('track', 'Purchase');
                }
            }).catch(err => {
                setLoaded(true);
                setIsFound(false)
                console.log("Data tidak ditemukan")
            })
        } catch {

        }

    }, [])
    const handleSubmit = (e) => {
        e.preventDefault();
        let order_id = e.target.booking_code.value;
        setParams(true)
        Services.getVoucher(order_id).then(res => {
            console.log(res.data.result)
            setLoaded(true);
            setIsFound(true);
            setData(res.data)
        }).catch(err => {
            setLoaded(true);
            setIsFound(false)
            console.log("Data tidak ditemukan")
        })
    }

    function handleBack() {
        setParams(false)
    }
    return (
        <Layout>
            <section class="contacts2 cid-sqz7gbxx6X" id="contacts2-0">
                <div class="container" style={{ height: "600px" }}>
                    <h2 class="mbr-section-title align-center mbr-fonts-style mbr-white mbr-bold display-2">Voucher</h2>
                    <h3 class="mbr-section-subtitle align-center mbr-fonts-style mbr-white mbr-regular mbr-pt-2 display-4" style={{ display: (is_params ? "none" : "") }}>Masukan kode pembayaran kamu</h3>
                    <div class="mbr-row mbr-jc-c mbr-pt-4" style={{ display: (is_params ? "none" : "") }}>
                        <div class="mbr-col-lg-7 mbr-col-md-10 mbr-col-sm-12" data-form-type="formoid">
                            <form class="mbr-form" onSubmit={handleSubmit} target="_top">
                                <div class="mbr-form-row">
                                    <div class="field mbr-col-sm-12 mbr-col">
                                        <input
                                            class="field-input display-4" type="text" placeholder="Kode pembayaran (contoh: VMT/VXE-693XXXX)" name="booking_code" required />
                                    </div>
                                    <div class="mbr-section-btn mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-12 align-center"><button
                                        type="submit" class="btn btn-success btn-form display-4">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="mbr-row mbr-jc-c" style={{ display: (is_params ? "" : "none") }}>
                        <div class="mbr-col-lg-7 mbr-col-md-10 mbr-col-sm-12" data-form-type="formoid">
                            {(is_load ? <CardTicket data={data} is_found={is_found} /> : "Loading...")}
                            <div class="card-footer text-white "><a onClick={handleBack}><i class="fas fa-arrow-left"></i> Kembali</a></div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default Voucher;