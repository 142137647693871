import axios from "axios";
import Strings from "./../../Services/Strings"


function getVoucher(order_id){
    let form =new FormData();
    form.append('order_id',order_id)
    return axios({
        method:"POST",
        baseURL:Strings.base_url+"voucher/get",
        data:form
    })
}


const Services={getVoucher};
export default Services;