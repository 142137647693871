import React, { Component } from 'react'
import PropTypes from 'prop-types'
import btfImage from "./assets/btf.png";

const Section3 = () => {
    return (
        <div class="container">
            <div class="text-center">
                <p class="text-2xl md:text-3xl">Sehebat apapun pejuang, <b style={{color:"#0d4273"}}>tak akan terasah tanpa latihan.</b></p>
                <p class="text-2xl md:text-3xl">Yuk, mulai latihan di Battlefield sekarang juga.</p>
                <p class="text-1xl md:text-2xl mt-5">Dapatkan paket <b>spesial</b> untuk mendukung program latihanmu,</p>
                <p class="text-1xl md:text-2xl"><b>UNLIMITED ACCESS BATTLEFIELD</b> untuk tanggal <font class="font-medium" style={{textDecoration:"underline",color:"#0d4273"}}>1 September 2021 – 30 November 2021</font></p>
                <p class="text-4xl mt-3 font-semibold" style={{color:"#0d4273"}}>Harga <el class="text-base" style={{textDecoration:"line-through"}}>Rp.105.000</el> Rp.90.000</p>
                <a href="#form">
                    <button class="flex btn border-none btn-primary mx-auto mt-5 w-80 rounded-2xl text-dark text-xl" style={{ background: "#50b6fe" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M2 6a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 100 4v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2a2 2 0 100-4V6z" />
                        </svg>
                        Dapatkan Voucher
                    </button>
                </a>
            </div>
        </div>
    )
}

export default Section3;